import styled from '@emotion/styled';
import { Asset } from 'contentful';
import { ReactNode } from 'react';
import { Video } from '../../../utils/api/videoApi';
import { breakpoints, spacing } from '../../../utils/styleguide';
import Image, { ImageStyleProps } from '../../contentful-elements/Image';
import ButtonPromptWrapper from '../ButtonPromptWrapper';
import PrimaryButton, { ButtonColor } from '../buttons/PrimaryButton';
import HeadlinerBackground from '../HeadlinerBackground';
import VideoPlayButton from '../VideoPlayButton';
import { Col, Container, Row } from './12ColGridLayout';

const StyledContainer = styled(Container)`
  padding: 180px 0 ${spacing[6]}px;
  ${breakpoints.desktop} {
    padding: 340px 0 ${spacing[11]}px;
  }
`;

const TitleWrapper = styled.div`
  margin-top: ${spacing[1]}px;
`;

const DescriptionWrapper = styled.div`
  margin-top: ${spacing[4]}px;
`;

const ButtonWrapper = styled.div`
  margin-top: ${spacing[6]}px;
`;

type HeadlinerSectionProps = {
  backgroundBlend?: string;
  backgroundColor?: string;
  backgroundLinear?: string;
  backgroundOverlay?: number;
  buttonSmall?: boolean;
  CTAColor?: ButtonColor;
  CTAPrompt?: string;
  CTAText?: string;
  description?: ReactNode;
  href?: string;
  id?: string;
  image?: Asset<'WITHOUT_UNRESOLVABLE_LINKS'>;
  landscapeBackgroundVideo?: Video;
  landscapeVideo?: Video;
  portraitBackgroundVideo?: Video;
  portraitVideo?: Video;
  preheader?: ReactNode;
  small?: boolean;
  title?: ReactNode;
};

const HeadlinerLayout = ({
  backgroundColor,
  backgroundLinear,
  backgroundOverlay,
  buttonSmall,
  CTAColor,
  CTAPrompt,
  CTAText,
  description,
  href,
  id,
  image,
  landscapeBackgroundVideo,
  landscapeVideo,
  portraitBackgroundVideo,
  portraitVideo,
  preheader,
  title,
}: HeadlinerSectionProps) => {
  let button;

  if (portraitVideo || landscapeVideo) {
    button = (
      <VideoPlayButton
        portraitVideo={portraitVideo}
        landscapeVideo={landscapeVideo}
        buttonLabel={CTAText}
      />
    );
  } else if (href) {
    button = (
      <ButtonPromptWrapper prompt={CTAPrompt}>
        <PrimaryButton
          externalLink
          href={href}
          size={(buttonSmall && 'small') || undefined}
          color={CTAColor}
        >
          {CTAText || 'Learn more'}
        </PrimaryButton>
      </ButtonPromptWrapper>
    );
  }

  return (
    <HeadlinerBackground
      id={id}
      backgroundColor={image ? undefined : backgroundColor}
      backgroundLinear={backgroundLinear}
      overlay={backgroundOverlay}
      Image={(props: ImageStyleProps) => <Image asset={image} {...props} />}
      portraitBackgroundVideo={portraitBackgroundVideo}
      landscapeBackgroundVideo={landscapeBackgroundVideo}
    >
      <StyledContainer>
        <Row>
          <Col>
            {preheader && preheader}
            {title && <TitleWrapper>{title}</TitleWrapper>}
          </Col>
        </Row>
        <Row>
          <Col desktop="span 8" tablet="span 10">
            {description && <DescriptionWrapper>{description}</DescriptionWrapper>}
            {CTAText && <ButtonWrapper>{button}</ButtonWrapper>}
          </Col>
        </Row>
      </StyledContainer>
    </HeadlinerBackground>
  );
};

export default HeadlinerLayout;
